/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * Content management API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IdentityResponse
 */
export interface IdentityResponse {
    /**
     * 
     * @type {string}
     * @memberof IdentityResponse
     */
    type?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IdentityResponse
     */
    isStrategy?: boolean;
}

/**
 * Check if a given object implements the IdentityResponse interface.
 */
export function instanceOfIdentityResponse(value: object): value is IdentityResponse {
    return true;
}

export function IdentityResponseFromJSON(json: any): IdentityResponse {
    return IdentityResponseFromJSONTyped(json, false);
}

export function IdentityResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdentityResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'] == null ? undefined : json['type'],
        'isStrategy': json['isStrategy'] == null ? undefined : json['isStrategy'],
    };
}

export function IdentityResponseToJSON(value?: IdentityResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'isStrategy': value['isStrategy'],
    };
}

