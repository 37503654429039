import {defineNuxtRouteMiddleware, navigateTo, useLogger} from '#imports';
import useLandingPageCheck from '~/composables/comatch/useLandingPageCheck';
import useEmailFromQueryParams from '~/composables/comatch/useEmailFromQueryParams';
import {Configuration, IdentityApi} from '@api';

export default defineNuxtRouteMiddleware(async () => {
    const logger = useLogger();

    useLandingPageCheck();

    const {email} = useEmailFromQueryParams();
    const api = new IdentityApi(new Configuration({basePath: ''}));
    let path: 'hello-client' | 'hello-freelancer' | 'hello-strategy' = 'hello-freelancer';

    try {
        const response = await api.findOne({findOneRequest: {email}});
        if (response?.type === 'CLIENT') {
            path = 'hello-client';
        } else if (response?.isStrategy) {
            path = 'hello-strategy';
        }
    } catch (e) {
        logger.error(e);
        /* laissez-passer */
    }
    return navigateTo({path, query: {email}});
});
