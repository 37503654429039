
/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * Content management API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {FetchOptions} from 'ofetch';
import type {
  FindOneRequest,
  IdentityResponse,
} from '../models';
import {
    FindOneRequestFromJSON,
    FindOneRequestToJSON,
    IdentityResponseFromJSON,
    IdentityResponseToJSON,
} from '../models';

export interface FindOneOperationRequest {
    findOneRequest: FindOneRequest;
}

/**
 * 
 */
export class IdentityApi extends runtime.BaseAPI {

    /**
     * Find one identity by email address
     * Find one identity
     */
    async findOneRaw(requestParameters: FindOneOperationRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<IdentityResponse>> {
        if (requestParameters['findOneRequest'] == null) {
            throw new runtime.RequiredError(
                'findOneRequest',
                'Required parameter "findOneRequest" was null or undefined when calling findOne().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request<IdentityResponse>({
            path: `/cms/api/identity/find-one`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FindOneRequestToJSON(requestParameters['findOneRequest']),
        }, initOverrides);

        if (response._data) { response._data=IdentityResponseFromJSON(response._data); }
        return response;
    }

    /**
     * Find one identity by email address
     * Find one identity
     */
    async findOne(requestParameters: FindOneOperationRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<IdentityResponse> {
        const response = await this.findOneRaw(requestParameters, initOverrides);
        return response._data;
    }

}
