/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * Content management API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FindOneRequest
 */
export interface FindOneRequest {
    /**
     * Email address
     * @type {string}
     * @memberof FindOneRequest
     */
    email: string;
}

/**
 * Check if a given object implements the FindOneRequest interface.
 */
export function instanceOfFindOneRequest(value: object): value is FindOneRequest {
    if (!('email' in value) || value['email'] === undefined) return false;
    return true;
}

export function FindOneRequestFromJSON(json: any): FindOneRequest {
    return FindOneRequestFromJSONTyped(json, false);
}

export function FindOneRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindOneRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
    };
}

export function FindOneRequestToJSON(value?: FindOneRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
    };
}

